
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import AJLoader from "@/components/AjaxLoader.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { CallRequestHistoryFilter } from "@/models";
import useCallRequest from "@/composables/useCallRequest";
import { GetCallRequestForViewDto } from "@/shared/service-proxies/service-proxies";
import AssignAgentModal from "@/components/modals/forms/AssignAgentModal.vue";

export default defineComponent({
  components: {
    Datatable,
    AJLoader,
    AssignAgentModal,
  },
  setup() {
    const loading = ref<boolean>(true);
    const {
      callRequestStats,
      callRequestHistory,
      getCallRequestStat,
      getCallRequestHistory,
    } = useCallRequest();

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Date",
        key: "date",
        sortable: true,
      },
      {
        name: "URNO",
        key: "urno",
      },
      {
        name: "Name",
        key: "name",
      },
      {
        name: "Phone Number",
        key: "phoneNumber",
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Assigned Agent",
        key: "assignedAgent",
      },
    ]);
    const tableData = ref<Array<GetCallRequestForViewDto> | undefined>([]);
    const dataFilter = ref<CallRequestHistoryFilter>({
      uRNOFilter: undefined,
      callRequestStatusFilter: -1,
      sorting: undefined,
      skipCount: 0,
      maxResultCount: 10000,
    });
    const modelValue = ref<GetCallRequestForViewDto>(
      null as unknown as GetCallRequestForViewDto
    );

    onMounted(async () => {
      setCurrentPageBreadcrumbs("CallRequest", ["CallRequest history"]);

      await getData();

      await getCallRequestStat();

      loading.value = false;
    });

    const getData = async () => {
      loading.value = true;

      await getCallRequestHistory(
        dataFilter.value as unknown as CallRequestHistoryFilter
      );

      tableData.value = callRequestHistory.value;

      loading.value = false;
    };

    const setModelOpen = (id: string) => {
      const value = callRequestHistory.value?.find(
        (el) => el.callRequest.id == id
      );

      if (value) {
        modelValue.value = value;
      }
    };

    return {
      loading,
      callRequestStats,
      tableData,
      tableHeader,
      dataFilter,
      moment,
      modelValue,
      getData,
      setModelOpen,
    };
  },
});
