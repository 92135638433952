
import moment from "moment";
import { defineComponent, onMounted, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import useCallRequest from "@/composables/useCallRequest";
import useAgents from "@/composables/useAgents";
import { GetCallRequestForViewDto } from "@/shared/service-proxies/service-proxies";
import { AgentsFilter } from "@/models";
import { emit } from "process";

export default defineComponent({
  props: {
    modelValue: {
      required: false,
      type: () => Object as unknown as GetCallRequestForViewDto,
    },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const assignAgentModalModalRef = ref<null | HTMLElement>(null);
    const { assignAgent } = useCallRequest();
    const { agents, getAgents } = useAgents();

    const loading = ref<boolean>(false);

    const defaultFormData = ref({
      callRequestId: "",
      agentId: "",
    });

    let formData = ref(defaultFormData);

    const rules = ref({
      productUnitName: [
        {
          required: true,
          message: "Product unit name is required",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;

          const { callRequestId, agentId } = formData.value;

          assignAgent(callRequestId, agentId)
            .then(() => {
              Swal.fire({
                text: "Agent assigned successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Okay!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(assignAgentModalModalRef.value);
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                text: "An error occured with your request.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
          loading.value = false;
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    watch(
      () => props.modelValue,
      () => {
        formData.value.callRequestId = props.modelValue?.callRequest
          .id as string;
      }
    );

    onMounted(async () => {
      const defaultAgentFilter = {
        filter: null,
        firstnameFilter: null,
        lastnameFilter: null,
        sorting: null,
        skipCount: undefined,
        maxResultCount: 1000,
        cancelToken: null,
      } as unknown as AgentsFilter;

      await getAgents(defaultAgentFilter);
    });

    const formatDate = (date: Date | undefined) => {
      return moment(date).format("DD-MMM-YYYY h:mm");
    };

    return {
      agents,
      assignAgentModalModalRef,
      formData,
      rules,
      formRef,
      loading,
      formatDate,
      submit,
    };
  },
});
